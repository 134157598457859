import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import { defaultLocale, i18n } from '@/utils/locale';
import '@/assets/main.pcss';
import globalProperties from '@/utils/globalProperties';
import Toast, { PluginOptions } from 'vue-toastification';
import VueTippy from 'vue-tippy';
import 'vue-toastification/dist/index.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import { UiActions } from '@/definitions';

const app = createApp(App);

app.use(i18n);
app.use(router);
app.use(store);
app.use(globalProperties);

const toastOptions: PluginOptions = { toastClassName: 'themed' };
app.use(Toast, toastOptions);

app.use(VueTippy, {
    defaultProps: {
        theme: 'light-border',
    },
});

app.mount('#app');

store.dispatch(UiActions.SET_LOCALE, defaultLocale).then();
