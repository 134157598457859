import { INavigationItem } from '@/definitions';
import {
    HomeIcon,
    CogIcon,
    UsersIcon,
    LockClosedIcon,
    CodeIcon,
    KeyIcon,
    BriefcaseIcon,
} from '@heroicons/vue/outline';
import { Permission } from '@/definitions/core-typings/Permission';
import type { FunctionalComponent, HTMLAttributes, VNodeProps } from 'vue';

const clearStateOnSetup = {
    query: {
        clearStateOnSetup: 'true',
    },
};
export interface IDashboardItem {
    title: string;
    description: string;
    callToAction: string;
    permission?: Permission;
    routeName?: string;
    icon: FunctionalComponent<HTMLAttributes & VNodeProps>;
}

export const SIDE_NAVIGATION_ITEMS: INavigationItem[] = [
    {
        localeKey: 'sideNavigation.home',
        icon: HomeIcon,
        name: 'Home',
    },
    {
        localeKey: 'sideNavigation.config',
        icon: CogIcon,
        name: 'Configurations',
    },
];

export const CONFIG_SIDE_NAVIGATION_ITEMS: INavigationItem[] = [
    {
        localeKey: 'core.entity.Users',
        icon: UsersIcon,
        name: 'ListUser',
        permission: Permission.VIEW_USERS,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'core.entity.Companies',
        icon: BriefcaseIcon,
        name: 'ListCompany',
        permission: Permission.VIEW_COMPANY,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'core.entity.Permissions',
        icon: LockClosedIcon,
        name: 'Permissions',
        permission: Permission.ASSIGN_PERMISSION_TO_ROLE,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'core.entity.Scripts',
        icon: CodeIcon,
        name: 'ListScript',
        permission: Permission.VIEW_SCRIPTS,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'core.entity.Requests',
        icon: KeyIcon,
        name: 'ListRequest',
        permission: Permission.VIEW_REQUESTS,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'core.entity.ApiTokens',
        icon: KeyIcon,
        name: 'ListApiToken',
        permission: Permission.VIEW_APITOKENS,
        ...clearStateOnSetup,
    },
    {
        localeKey: 'sideNavigation.home',
        icon: HomeIcon,
        name: 'Home',
    },
];
